import React from "react";
import { Box, SxProps, useMediaQuery } from "@mui/material";
import { DebounceInput, Button, Icon } from "@/shared/components";
import colors from "@/lib/palette";
import theme from "@/lib/theme";

type Props = {
  value: string;
  variant?: "primary" | "secondary";
  sx?: SxProps;
  sxInput?: SxProps;
  onInput: (value: string) => void;
  onFocus?: () => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleSubmitSearch: () => void;
};

const SearchFieldComponent = ({
  value,
  variant,
  sx,
  sxInput,
  onInput,
  onFocus,
  onKeyDown,
  handleSubmitSearch,
}: Props) => {
  const isLgScreen = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box
      bgcolor={colors.white}
      borderRadius={"60px"}
      padding={
        variant === "primary"
          ? {
              xs: "4px 4px 4px 16px",
              lg: "8px 8px 8px 24px",
              xl: "12px 12px 12px 32px",
            }
          : "6px 6px 6px 32px"
      }
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      border={`1px solid ${colors.black300}`}
      sx={sx}
    >
      <DebounceInput
        value={value}
        onInputDebounce={onInput}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        placeholder={`Enter resource, technology or company ${
          !isLgScreen ? "name" : ""
        }`}
        sx={{
          flex: 1,
          fontSize: {
            xs: "12px",
            lg: "16px",
            xl: "18px",
          },
          ...sxInput,
        }}
      />

      <Button
        variant={variant === "primary" ? "filled" : "outlined-primary"}
        onClick={handleSubmitSearch}
        sx={{
          borderRadius: "50%",
          padding: "0px",
          width:
            variant === "primary"
              ? {
                  xs: "32px",
                  lg: "40px",
                  xl: "52px",
                }
              : {
                  xs: "36px",
                  xl: "48px",
                },
          height:
            variant === "primary"
              ? {
                  xs: "32px",
                  lg: "40px",
                  xl: "52px",
                }
              : {
                  xs: "36px",
                  xl: "48px",
                },
          ml: "8px",
        }}
      >
        <Icon
          icon="search"
          width={
            variant === "primary"
              ? { xs: "16px", lg: "24px", xl: "28px" }
              : {
                  xs: "16px",
                  xl: "28px",
                }
          }
          height={
            variant === "primary"
              ? { xs: "16px", lg: "24px", xl: "28px" }
              : {
                  xs: "16px",
                  xl: "28px",
                }
          }
          color={variant === "primary" ? colors.white : colors.blue}
        />
      </Button>
    </Box>
  );
};

export default SearchFieldComponent;
