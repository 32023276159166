"use client";
import * as React from "react";
import Link from "next/link";
import { Box, Typography } from "@mui/material";
import colors from "@/lib/palette";

const onGetHistory = (): string[] => {
  return JSON.parse(localStorage.getItem("searchHistory") || "[]");
};

export default function SearchHistory() {
  const history = onGetHistory();

  return (
    <Box>
      <Typography
        component="div"
        variant="h5"
        sx={{
          color: colors.black500,
          padding: "0 0 16px 0",
        }}
      >
        Search history
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        {history.length ? (
          history.map((historyItem, index) => (
            <Link
              key={`history_${index}`}
              href={`/library?query=${historyItem}&type=resources&pageCount=12`}
            >
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 400,
                  cursor: "pointer",
                }}
              >
                {historyItem}
              </Typography>
            </Link>
          ))
        ) : (
          <Typography
            variant="h4"
            sx={{
              fontWeight: 400,
              cursor: "pointer",
            }}
          >
            No search history
          </Typography>
        )}
      </Box>
    </Box>
  );
}
