import React from "react";
import { SearchModelType } from "../search.types";
import SearchWithoutResult from "./search-without-result.component";
import SearchTechnologies from "./search-technologies.component";
import SearchResources from "./search-resources.component";
import SearchCompanies from "./search-companies.component";

type Props = {
  results: SearchModelType;
  query: string;
  handleHideMenu: () => void;
};

const SearchResultsComponent = ({ results, handleHideMenu, query }: Props) => {
  if (
    !results.resources.length &&
    !results.companies.length &&
    !results.categories.length
  ) {
    return <SearchWithoutResult />;
  }

  return (
    <>
      <SearchTechnologies
        items={results.categories}
        query={query}
        handleHideMenu={handleHideMenu}
      />
      <SearchCompanies
        items={results.companies}
        query={query}
        handleHideMenu={handleHideMenu}
      />
      <SearchResources
        items={results.resources}
        query={query}
        handleHideMenu={handleHideMenu}
      />
    </>
  );
};

export default SearchResultsComponent;
