import React from "react";

import { ResourceSearchModelType } from "../search.types";
import { Box, Stack, Typography } from "@mui/material";
import colors from "@/lib/palette";
import Link from "next/link";

type Props = {
  items: ResourceSearchModelType[];
  query: string;
  handleHideMenu: () => void;
};
function SearchResourcesComponent({ items, query, handleHideMenu }: Props) {
  if (!items.length) {
    return null;
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 0 16px 0",
        }}
      >
        <Typography
          component="div"
          variant="body2"
          sx={{
            color: colors.black500,
          }}
        >
          Resources
        </Typography>
        <Link
          href={`/library?type=resources&query=${query}`}
          onClick={handleHideMenu}
        >
          <Typography
            variant="body2"
            sx={{
              color: colors.blue,
            }}
          >
            See all results
          </Typography>
        </Link>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "22px",
        }}
      >
        {items.map((resource) => (
          <Link
            key={`resource${resource.id}`}
            href={`/resource/${resource.id}`}
            onClick={handleHideMenu}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "7px",
              }}
            >
              <Stack direction="row" spacing={1}>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{
                    color: colors.black700,
                  }}
                >
                  {resource.company_name || "No company"}
                </Typography>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{
                    color: colors.black700,
                  }}
                >
                  ·
                </Typography>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{
                    color: colors.black700,
                  }}
                >
                  {resource.type || "No type"}
                </Typography>
              </Stack>
              <Typography
                variant="h4"
                sx={{
                  cursor: "pointer",
                }}
              >
                {resource.name}
              </Typography>
            </Box>
          </Link>
        ))}
      </Box>
    </Box>
  );
}

export default SearchResourcesComponent;
