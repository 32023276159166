import React from "react";
import { TechnologySearchModelType } from "../search.types";
import Link from "next/link";
import { Box, Typography } from "@mui/material";
import colors from "@/lib/palette";
import { higlightedText } from "@/shared/utils";

type Props = {
  items: TechnologySearchModelType[];
  query: string;
  handleHideMenu: () => void;
};

function SearchTechnologiesComponent({ items, query, handleHideMenu }: Props) {
  if (!items.length) {
    return null;
  }
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 0 16px 0",
        }}
      >
        <Typography
          component="div"
          variant="body2"
          sx={{
            color: colors.black500,
          }}
        >
          Technologies
        </Typography>
        <Link href="/technologies" onClick={handleHideMenu}>
          <Typography
            variant="body2"
            sx={{
              color: colors.blue,
            }}
          >
            See all results
          </Typography>
        </Link>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        {items.map((category) => (
          <Link
            href={`/library?categories=${category.value}&type=companies&page=1&pageCount=12`}
            key={`category${category.id}`}
            onClick={handleHideMenu}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: 400,
                cursor: "pointer",
                "& span": {
                  fontWeight: 700,
                },
              }}
            >
              {higlightedText(category.name, query)}
            </Typography>
          </Link>
        ))}
      </Box>
    </Box>
  );
}

export default SearchTechnologiesComponent;
