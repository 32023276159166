"use client";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/navigation";
import { Box, SxProps, useMediaQuery } from "@mui/material";
import { ClickOutside, DebounceInput } from "@/shared/components";

import SearchField from "./components/search-field.component";
import SearchResultsBox from "./components/search-results-box.component";

import SearchApi, { type SearchModelType } from "./api/search.api";
import theme from "@/lib/theme";

const onSaveToHistory = (value: string) => {
  const history: string[] = JSON.parse(
    localStorage.getItem("searchHistory") || "[]"
  );

  if (!history.includes(value)) {
    history.unshift(value);

    const updatedHistory: string[] = [...history];

    localStorage.setItem(
      "searchHistory",
      JSON.stringify([...updatedHistory].slice(0, 5))
    );
  }
};

type Props = {
  variant?: "primary" | "secondary";
  sx?: SxProps;
};

function SearchFeature({ sx, variant = "primary" }: Props) {
  const router = useRouter();
  const isMdSize = useMediaQuery(theme.breakpoints.down("md"));

  const [value, setValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const [result, setResult] = useState<SearchModelType>({
    resources: [],
    categories: [],
    companies: [],
  });

  const searchStarted = useMemo(() => {
    const trimmedValue = value.trim();

    const isSearchStarted = trimmedValue.length >= 2;

    if (isSearchStarted) {
      onSaveToHistory(trimmedValue);
    }

    return isSearchStarted;
  }, [value]);

  const fetchSearch = useCallback(
    async (signal?: AbortSignal) => {
      setLoading(true);
      try {
        const { data } = await SearchApi.search({ query: value }, signal);
        setResult({
          categories: data.categories || [],
          companies: data.companies || [],
          resources: data.resources || [],
        });
        setLoading(false);
      } catch (error) {
        if (error instanceof Error && error.message === "Request was aborted") {
          return;
        }
        setLoading(false);
      }
    },
    [value]
  );

  const handleEnterPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter" && value.trim().length >= 2) {
        setShowMenu(false);

        router.push(
          `/library?query=${value}&type=resources&page=1&pageCount=12`
        );
      }
    },
    [router, value]
  );

  const handleSubmitSearch = useCallback(() => {
    if (value.trim().length >= 2) {
      setShowMenu(false);

      router.push(`/library?query=${value}&type=resources&page=1&pageCount=12`);
    }
  }, [router, value]);

  useEffect(() => {
    if (searchStarted) {
      const controller = new AbortController();
      const { signal } = controller;

      fetchSearch(signal);

      return () => {
        controller.abort();
      };
    }
  }, [fetchSearch, searchStarted]);

  if (isMdSize && variant === "secondary") {
    return (
      <ClickOutside
        onClickOutside={() => setShowMenu(false)}
        sx={{
          position: "static",
          ...sx,
        }}
      >
        <DebounceInput
          value={value}
          onInputDebounce={setValue}
          onFocus={() => setShowMenu(true)}
          placeholder={`Enter resource, technology or company name`}
          onKeyDown={handleEnterPress}
          sx={{
            flex: 1,
            width: "100%",
            fontSize: {
              xs: "12px",
              lg: "16px",
              xl: "18px",
            },
          }}
        />

        {showMenu && (
          <SearchResultsBox
            searchStarted={searchStarted}
            results={result}
            loading={loading}
            query={value}
            handleHideMenu={() => setShowMenu(false)}
            sx={{
              left: 0,
              border: "none",
              borderRadius: 0,
              top: "100%",
              mt: 0,
            }}
          />
        )}
      </ClickOutside>
    );
  }

  return (
    <ClickOutside onClickOutside={() => setShowMenu(false)} sx={sx}>
      <Box
        sx={{
          position: "relative",
        }}
      >
        <SearchField
          value={value}
          variant={variant}
          onInput={setValue}
          onFocus={() => setShowMenu(true)}
          onKeyDown={handleEnterPress}
          handleSubmitSearch={handleSubmitSearch}
        />

        {showMenu && (
          <SearchResultsBox
            searchStarted={searchStarted}
            results={result}
            loading={loading}
            query={value}
            handleHideMenu={() => setShowMenu(false)}
          />
        )}
      </Box>
    </ClickOutside>
  );
}

export default SearchFeature;
