import React from "react";
import { Box, Typography } from "@mui/material";
import { CompanySearchModelType } from "../search.types";
import colors from "@/lib/palette";
import Link from "next/link";
import Image from "next/image";

type Props = {
  items: CompanySearchModelType[];
  query: string;
  handleHideMenu: () => void;
};

function SearchCompaniesComponent({ items, query, handleHideMenu }: Props) {
  if (!items.length) {
    return null;
  }
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 0 16px 0",
        }}
      >
        <Typography
          component="div"
          variant="body2"
          sx={{
            color: colors.black500,
          }}
        >
          Companies
        </Typography>

        <Link
          href={`/library?type=companies&query=${query}&page=1&pageCount=12`}
          onClick={handleHideMenu}
        >
          <Typography
            variant="body2"
            sx={{
              color: colors.blue,
            }}
          >
            See all results
          </Typography>
        </Link>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        {items?.map((company) => {
          return (
            <Link
              href={`/company/${company.id}`}
              key={`company${company.id}`}
              onClick={handleHideMenu}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Image
                  src={
                    company.image
                      ? company.image
                      : "/icons/company-default-img.png"
                  }
                  loading="lazy"
                  alt={`Company ${company.name} icon`}
                  width={60}
                  height={60}
                  style={{ objectFit: "contain" }}
                />

                <Typography
                  component="div"
                  variant="h4"
                  sx={{
                    padding: "0 0 3px 0",
                    ml: '20px',
                    fontWeight: 700,
                    cursor: "pointer",
                  }}
                >
                  {company.name}
                </Typography>
              </Box>
            </Link>
          );
        })}
      </Box>
    </Box>
  );
}

export default SearchCompaniesComponent;
