"use client";
import React, { useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import colors from "@/lib/palette";

import { Icon, Button } from "@/shared/components";

type Props = {
  loading: boolean;
  children?: React.ReactNode;
  total_items: number;

  iHasFilters?: boolean;
  onClearFilters?: () => void;
  onApplyFilters?: () => void;
};

function FiltersModal({
  loading,
  children,
  total_items,

  iHasFilters,

  onApplyFilters,
  onClearFilters,
}: Props) {
  const [open, setOpen] = useState(false);

  const handleApplyFilters = () => {
    setOpen(false);

    if (onApplyFilters) {
      onApplyFilters();
    }
  };

  return (
    <Box>
      <Button
        onClick={() => setOpen(true)}
        sx={{
          border: `1px solid ${colors.steel}`,
          backgroundColor: colors.white,
          width: "40px",
          height: "40px",
          flex: 0,
          marginLeft: "8px",
          borderRadius: "4px",
        }}
      >
        {iHasFilters ? (
          <Box
            sx={{
              width: "4px",
              height: "4px",
              backgroundColor: colors.blue,
              position: "absolute",
              borderRadius: "50%",
              top: "3px",
              right: "3px",
            }}
          />
        ) : (
          ""
        )}
        <Icon icon="filters" width={16} height={16} />
      </Button>

      {open ? (
        <Box
          sx={{
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: colors.white,
            position: "fixed",
            zIndex: 100,
          }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mb={"20px"}
            padding={"16px 24px"}
          >
            <Typography
              component="span"
              sx={{
                fontSize: "20px !important",
                fontWeight: "bold",
                lineHeight: "24px",
                color: colors.black,
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              Filter results
              <Icon
                icon="close"
                height={"28px"}
                width={"28px"}
                sx={{
                  ml: "auto",
                  cursor: "pointer",
                }}
                onClick={() => setOpen(false)}
              />
            </Typography>
          </Box>

          <Box
            padding={"16px 24px"}
            sx={{
              maxHeight: "calc(100svh - 116px)",
              overflowY: "auto",
            }}
          >
            {children}
          </Box>

          <Box
            display={"grid"}
            gridTemplateColumns={"1fr 1fr"}
            columnGap={"8px"}
            padding={"8px"}
            bgcolor={colors.white}
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              width: "100%",
            }}
          >
            <Button variant="outlined-primary" onClick={onClearFilters}>
              Reset Filters
            </Button>
            <Button
              disabled={loading}
              variant="filled"
              onClick={handleApplyFilters}
            >
              Show Results (
              {loading ? (
                <CircularProgress
                  size={14}
                  sx={{
                    color: `${colors.white} !important`,
                  }}
                />
              ) : (
                total_items || 0
              )}
              )
            </Button>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
}

export default FiltersModal;
