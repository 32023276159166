import { Box, Skeleton, SxProps } from "@mui/material";

type Props = {
  sx?: SxProps;
};

const LoaderItem = () => {
  return (
    <Box display="flex" alignItems="flex-start">
      <Box height={"60px"}>
        <Skeleton
          width={60}
          height={"100%"}
          sx={{
            transform: "none",
          }}
        />
      </Box>

      <Box ml={2.5}>
        <Skeleton width={"90px"} height={"25px"} />
        <Skeleton width={"111px"} height={"18px"} />
      </Box>
    </Box>
  );
};

const SearchLoaderComponent = ({ sx }: Props) => {
  return (
    <Box sx={sx}>
      <Skeleton
        width={"100%"}
        height={"60px"}
        sx={{
          transform: "none",
        }}
      />

      <Box display="grid" gridTemplateAreas="1fr" rowGap="40px">
        <Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Skeleton width={"90px"} height={"24px"} />

            <Skeleton width={"111px"} height={"19px"} />
          </Box>

          <Box mt={2} display={"grid"} gridTemplateAreas="1fr" rowGap="12px">
            <LoaderItem />
            <LoaderItem />
            <LoaderItem />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SearchLoaderComponent;
