import React from "react";
import { Paper, SxProps } from "@mui/material";
import colors from "@/lib/palette";
import SearchHistory from "./search-history.component";
import { SearchModelType } from "../search.types";
import SearchLoader from "./search-loader.component";
import SearchResults from "./search-results.component";

type Props = {
  searchStarted: boolean;
  results: SearchModelType;
  loading: boolean;
  query: string;
  sx?: SxProps;
  handleHideMenu: () => void;
};

const SearchResultsBoxComponent = ({
  searchStarted,
  loading,
  results,
  query,
  sx,
  handleHideMenu,
}: Props) => {
  return (
    <Paper
      sx={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        gap: "40px",
        width: "100%",
        marginTop: "8px",
        zIndex: 4,
        padding: "32px",
        background: colors.white,
        borderRadius: "16px",
        border: `1px solid ${colors.blue}`,
        maxHeight: "calc(100vh - 64px)",
        overflow: "auto",
        ...sx,
      }}
    >
      {loading ? (
        <SearchLoader />
      ) : !searchStarted ? (
        <SearchHistory />
      ) : (
        <SearchResults
          results={results}
          query={query}
          handleHideMenu={handleHideMenu}
        />
      )}
    </Paper>
  );
};

export default SearchResultsBoxComponent;
