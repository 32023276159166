"use client";

import Image from "next/image";

import { Box, Modal, SxProps, useMediaQuery } from "@mui/material";

import colors from "@/lib/palette";
import theme from "@/lib/theme";

import { Icon } from "@/shared/components";

type Props = {
  open: boolean;
  handleClose: () => void;
  children?: React.ReactNode;
  sx?: SxProps;
};

const inititalStyle: SxProps = {
  position: "absolute",
  top: "15svh",
  left: "50%",
  maxWidth: 640,
  width: "90%",
  padding: {
    xs: "36px 32px",
    sm: "64px 60px",
  },
  outline: "none",
  transform: "translateX(-50%)",
  backgroundColor: colors.blue10,
  borderRadius: 4,
  border: `1px solid ${colors.blue30}`,
};

const GeneralModal = (props: Props) => {
  const { open, handleClose, sx, children } = props;

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const logoWidth = isMobile ? 75 : 114;
  const logoHeight = isMobile ? 19 : 28;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        overflowY: "auto",
      }}
    >
      <Box sx={{ ...inititalStyle, ...sx }}>
        <Box
          sx={{
            position: "absolute",
            width: 32,
            height: 32,
            top: 30,
            right: 30,
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          <Icon icon="close" width={32} height={32} />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginBottom={4}
        >
          <Image
            src="/logo/logo.svg"
            alt="Inkhub"
            unoptimized
            priority
            width={logoWidth}
            height={logoHeight}
          />
        </Box>

        {children}
      </Box>
    </Modal>
  );
};

export default GeneralModal;
