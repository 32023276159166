import { Box, SxProps, Typography } from "@mui/material";
import { Icon } from "@/shared/components";
import colors from "@/lib/palette";

type Props = {
  sx?: SxProps;
};

function SearchWithoutResultComponent({ sx }: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "17px",
        ...sx,
      }}
    >
      <Icon
        width={{ xs: 32 }}
        height={{ xs: 35 }}
        color={colors.steel}
        icon="search-nodata"
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Typography
          component="div"
          variant="h3"
          sx={{
            color: colors.black,
          }}
        >
          Sorry, but no entities for this query
        </Typography>
        <Typography
          component="span"
          variant="body2"
          sx={{
            color: colors.black,
            fontWeight: 400,
          }}
        >
          We recommend rephrasing your search query or using a different query
        </Typography>
      </Box>
    </Box>
  );
}

export default SearchWithoutResultComponent;
